import {
  Links,
  Meta,
  Outlet,
  Scripts } from
"@remix-run/react";

import tailwindCSSStylesRef from "./styles/tailwind.css";
import indexStylesRef from "./styles/index.css";
import { DESCRIPTIONS, TITLES } from "./meta";

export function meta() {
  return {
    charset: "utf-8",
    viewport:
    "width=device-width, minimum-scale=1.0, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover, user-scalable=no",
    "og:type": "website",
    "og:title": TITLES.HOME,
    "og:description": DESCRIPTIONS.HOME,
    "og:image": "/share.webp",
    "og:url": "https://your-website-url.com",
    "twitter:card": "summary_large_image",
    "twitter:title": TITLES.HOME,
    "twitter:description": DESCRIPTIONS.HOME,
    "twitter:image": "/share.webp"
  };
}

export function links() {
  return [
  {
    rel: "icon",
    href: "/favicon.ico"
  },
  {
    rel: "preconnect",
    href: "https://fonts.googleapis.com"
  },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "true"
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
  },
  { rel: "stylesheet", href: tailwindCSSStylesRef },
  { rel: "stylesheet", href: indexStylesRef }];

}

export default function Root() {
  return (
    <html
      lang="en"
      style={{ backgroundColor: 'black', color: 'black', visibility: 'hidden' }}
      className="h-full font-sans selection:bg-white/80 selection:text-black/80">

      <head>
        <meta name="color-scheme" content="light" />
        <Meta />
        <Links />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin="" />

        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4M5PYCYQ5L');
          `
          }}>
        </script>
      </head>
      <body className="h-full bg-black text-white">
        <Outlet />
        <Scripts />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4M5PYCYQ5L"></script>
      </body>
    </html>);

}